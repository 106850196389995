.Projects {
    position: relative;
    background-color:#FFAAEA;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    
}

.ProjectsTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 0;
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
    text-align: center;
    
}

.ProjectsHeader {
    position:absolute;
    top: 0;
        
}

.ProjectsText {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    top: 0;
    font-size: 1.30rem;
    font-weight: bold;
    text-align: center;
    color: #534D56;
    line-height: 1.5;
    letter-spacing: 1px;
    text-shadow: 1px 1px 1px #FB6FC6;
}

.EggCarton {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 15%;
}

.ProjEgg {
    height: auto;
    width: 100%;
    max-width: 350px;
    margin: 4rem;
  }

  .ProjEgg:hover {
   cursor: pointer;
  }

  .ProjEgg:active {
    transform: translateY(4px);
    border-radius: 20%;
    
  }

  /* Mobile Screens */
  @media only screen and (max-width: 480px) {
    .Projects {
        /* width: 90%; */
        min-height: auto;
        padding-bottom: 20px;
        
    }
    
    .ProjectsTitle {
       width: 100%;
       height: auto;
       padding-top: 1rem;
      
       
    }
    
    .ProjectsHeader {
        position:relative;
        width: 100%;
            
    }
    
    .ProjectsText {
        width: 100%;
        font-size: 0.90em;
        margin-bottom: -30%;
        
    }
    
    .EggCarton {
        margin-top: 40%;
    }
    
    .ProjEgg {
        height: 175px;
        width: 150px;
        margin-top: 20px;
        margin-bottom: 20px;
        
      }
    
  }

  @media only screen and (max-width: 320px) {
    .ProjectsHeader {
      width: 90%;
          
  }

  .Projects {
   
    min-height: auto;
    padding-bottom: 20px;
    
}
  }

  /* Tablet Screens */

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .Projects {
      width: 100%;
      min-height: auto;
      padding-bottom: 20px;
      
  }

  .ProjectsTitle {
     width: 70%;
     height: auto;
     left: 15%;
    
    
     
  }
  
  .ProjectsHeader {
      position:relative;
      width: 100%;
          
  }
  
  .ProjectsText {
      width: 100%;
      font-size: 0.90em;
      margin-bottom: -20%;
      
      
  }
  
  .EggCarton {
      margin-top: 40%;
  }
  
  .ProjEgg {
      height: 275px;
      width: 250px;
      margin-top: 20px;
      margin-bottom: 20px;
      
    }
  }



  /* Normal Laptop Screens  */

  @media (min-width: 1280px) and (max-width: 1919px) {
   
    .EggCarton {
      margin-top: 22%;
    }
    
    .ProjEgg {
        margin: 3rem;
      }
    
    
  }

  
 /* Mac Air*/
 @media (min-width: 768px) and (max-width: 1280px) {
  .EggCarton {
    margin-top: 22%;
  }
  
  .ProjEgg {
      margin: 1rem;
    }
 }