.TipsyPage {
    background-color: #EDE768;
    color: hotpink;
    text-align: center; 
}

.TipsyTitle {
    flex-direction: column;
    justify-content: flex-start; 
    margin-bottom: 1rem; 
    left: auto;
    max-width: 80%;
    
    

}

.TipsyOctoCat {
    width: 10%; 
    height: auto; 
    margin-top: 1rem;
    margin-bottom: 2rem; 
    
}

.TipsyOctoCat:hover {
    cursor: pointer;
}

.TipsyOctoCat:active {
    transform: scale(1.1);
}

.TipsyAppEgg {
    width: 8%;
    height: auto; 
    margin-bottom: 2rem; 
    margin-top: 1rem; 
    margin-left: 32rem;
    
}

.TipsyAppEgg:hover {
    cursor: pointer;
}

.TipsyAppEgg:active {
    transform: scale(1.1);
}

.TipsyApp {
    width: 110%; 
    height: auto; 
    margin: 3rem 0; 
    border-radius: 1rem;
    max-width: 900px; 
    margin-bottom: 2rem;
    margin-left: 5rem;
    margin-right: 2rem;
}

.TipsyContainer {
    display: flex;
    align-items: flex-start;
    margin-bottom: 2rem;
}

.TipsyText {
    display:flex;
    flex:0.9;
    flex-direction: column;
    justify-content: flex-end;
    position:relative;
    align-self: flex-start;
    text-align: left;
    font-size: 1.12em;
    color: #534D56;
}





/* Normal Laptop Screens  */
@media only screen and (min-width: 1280px) and (max-width: 1919px) {
    
    .TipsyTitle {
    flex-direction: column;
    justify-content: flex-start; 
    margin-bottom: 1rem; 
    max-width: 80%;
    margin-left: -4rem;
       
        
           
    }
    
    .TipsyOctoCat {
        width: 12%;
        height: auto;
        margin-bottom: 0px;
        margin-left: 1%;
        
    }
    
    .TipsyOctoCat:hover {
        cursor: pointer;
    }
    
    .TipsyOctoCat:active {
        transform: scale(1.1);
    }
    
    .TipsyAppEgg {
        width: 10%;
        height: auto;
        margin: 0 auto;
        margin-bottom: 0%;
        margin-left: 10rem;
        
      
    }
    
    .TipsyAppEgg:hover {
        cursor: pointer;
    }
    
    .TipsyAppEgg:active {
        transform: scale(1.1);
    }
    
    .TipsyApp {
        width: 50%;
        height: 50%;
        margin-left: 70px;
        margin-right: 20px;
        margin-bottom: 20px;
        margin-top: 40px;
        border-radius:30px;
    }
    
    .TipsyContainer {
        display: flex;
        align-items: flex-start;
        
    }
    .TipsyText {
        display:flex;
        flex:0.9;
        flex-direction: column;
        justify-content: flex-end;
        position:relative;
        align-self: flex-start;
        font-size: 1.05em;
        color: #534D56;
        
    }
}

 /* Mac Air*/
 @media (min-width: 768px) and (max-width: 1280px) {
    .TipsyTitle {
        flex-direction: column;
        justify-content: flex-start; 
        margin-bottom: 1rem; 
        max-width: 80%;
        
           
            
               
        }
        
        .TipsyOctoCat {
            width: 12%;
            height: auto;
            margin-bottom: 0px;
            margin-left: 1%;
            
        }

        .TipsyAppEgg {
            width: 10%;
            height: auto;
            margin: 0 auto;
            margin-bottom: 0%;
           
            
          
        }
 }


/* Mobile Screens */
@media only screen and (min-width:320px) and (max-width: 480px) {

    .TipsyPage {
        width: 100%;
        max-width: 100%;
        background-color: #EDE768;
        color: hotpink
    }
    
    .TipsyTitle {
        width: 100%;
        height: auto;
        
   
        
    }
    
    .TipsyOctoCat {
        width: 100px;
        height: 90px;
        margin: 0 auto;
        margin-bottom: 75px;
        
    }
    
    .TipsyOctoCat:hover {
        cursor: pointer;
    }
    
    .TipsyOctoCat:active {
        /* background-color: #EDE768; */
        transform: scale(1.1);
    }
    
    .TipsyAppEgg {
        width: 80px;
        height: 95px;
        margin: 0 auto;
        margin-bottom: 75px;
       
      
    }
    
    .TipsyAppEgg:hover {
        cursor: pointer;
    }
    
    .TipsyAppEgg:active {
        /* background-color: #EDE768; */
        transform: scale(1.1);
    }
    
    .TipsyApp {
        width: 90%;
        height: auto;
        margin-left: 2rem;
        margin-top: -30px;
        
    }
    
    .TipsyContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        
    }
    .TipsyText {
        display:flex;
        flex:1;
        flex-direction: column;
        position:relative;
        align-self: flex-start;
        text-align: center;
        font-size: 1em;
        color: #534D56;
        padding: 0 10px;
        
    }

    
    

}

/* Large Mobile Screens */
@media only screen and (min-width: 391px) and (max-width: 767px) {

    .TipsyPage {
        background-color: #EDE768;
        color: hotpink
    }
    
    .TipsyTitle {
        width: 100%;
        height: auto;
       
        
    }
    
    .TipsyOctoCat {
        width: 100px;
        height: 90px;
        margin: 0 auto;
        margin-bottom: 75px;
        
    }
    
    .TipsyOctoCat:hover {
        cursor: pointer;
    }
    
    .TipsyOctoCat:active {
        
        transform: scale(1.1);
    }
    
    .TipsyAppEgg {
        width: 80px;
        height: 95px;
        margin: 0 auto;
        margin-bottom: 75px;
        margin-left: 25%;
      
    }
    
    .TipsyAppEgg:hover {
        cursor: pointer;
    }
    
    .TipsyAppEgg:active {
        
        transform: scale(1.1);
    }
    
    .TipsyApp {
        width: 90%;
        height: auto;
        margin-left: 17px;
        margin-top: -30px;
    }
    
    .TipsyContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        
    }
    .TipsyText {
        display:flex;
        flex:1;
        flex-direction: column;
        position:relative;
        align-self: flex-start;
        font-size: 1em;
        color: #534D56;
        padding: 0 10px;
        
    }
    

}
/* Large Screens */
/* @media only screen and (min-width: 1920px) and (max-width: 2560px) {

    .TipsyTitle {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 20px;
        margin-top: 20px;
       
        
    }
    
    .TipsyOctoCat {
        width: 10%;
        height: auto;
        margin-left: 3%;
     }
    
    .TipsyOctoCat:hover {
        cursor: pointer;
    }
    
    .TipsyOctoCat:active {
        
        transform: scale(1.1);
    }
    
    .TipsyAppEgg {
        width: 8%;
        height: auto;
        margin-left: 25%;
      
    }
    
    .TipsyAppEgg:hover {
        cursor: pointer;
    }
    
    .TipsyAppEgg:active {
      
        transform: scale(1.1);
    }
    
    .TipsyApp {
        width: 1100px;
        height: 900px;
        margin-left: 70px;
        margin-right: 20px;
        margin-bottom: 20px;
        margin-top: 40px;
        border-radius:30px;
    }
    
    .TipsyContainer {
        display: flex;
        align-items: flex-start;
        
    }
    .TipsyText {
        display:flex;
        flex:0.9;
        flex-direction: column;
        justify-content: flex-end;
        position:relative;
        align-self: flex-start;
        font-size: 20px;
        color: #534D56;
        
    }
} */