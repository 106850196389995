.AboutMe {
    width: 80%;
    height: auto;
    margin: 0 auto;
    margin-top: 5%;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-61%, -15%);
    border:1rem solid #FB6FC6;
    border-radius: 5px;
    background-color: #EDE768;
    box-shadow: 0 4px 8px 0 #534D56;
 
}

.AboutMeTitle {
    height: auto;
    width: 40%;
    

}

.AboutMeText {
    text-align: center;
    font-family: arial;
    font-size: 0.5em;
    padding: 2%;
    color: #534D56;
    font-family: 'Fira Code', monospace;
}

/*Mobile Screens */
@media only screen and (min-width: 390px) and (max-width:480px) {
    

.AboutMe {
    width: 90%;
    height:auto;
    margin: 0 auto;
    margin-top: 250%;
    margin-bottom: 10%;
    position: relative;
    transform: translate(-53%, -65%);
    border:10px solid #FB6FC6;
    border-radius: 5px;
    background-color: #EDE768;
    box-shadow: 0 4px 8px 0 #534D56;
    margin-bottom: -230%;
 
}

.AboutMeTitle {
    position: relative;
    height: 40%;
    width: 80%;
    

}

.AboutMeText {
    font-size: 0.7em;
   padding: 2%;
}
}

@media only screen and (min-width: 361px) and (max-width:389px) {
    

    .AboutMe {
        width: 90%;
        height:auto;
        margin: 0 auto;
        margin-top: 255%;
        margin-bottom: 10%;
        position: relative;
        transform: translate(-53%, -65%);
        border:10px solid #FB6FC6;
        border-radius: 5px;
        background-color: #EDE768;
        box-shadow: 0 4px 8px 0 #534D56;
        margin-bottom: -235%;
     
    }
    
    .AboutMeTitle {
        position: relative;
        height: 40%;
        width: 80%;
        
    
    }
    
    .AboutMeText {
        font-size: 0.7em;
       padding: 2%;
    }
    }
    
    /* Large mobiles */
    @media only screen and (min-width: 412px) and (max-width:428px) {
    

        .AboutMe {
            width: 90%;
            height:auto;
            margin: 0 auto;
            margin-top: 225%;
            margin-bottom: 10%;
            position: relative;
            transform: translate(-53%, -65%);
            border:10px solid #FB6FC6;
            border-radius: 5px;
            background-color: #EDE768;
            box-shadow: 0 4px 8px 0 #534D56;
            margin-bottom: -205%;
         
        }
        
        .AboutMeTitle {
            position: relative;
            height: 40%;
            width: 80%;
            
        
        }
        
        .AboutMeText {
            font-size: 0.7em;
           padding: 2%;
        }
        }
        

@media only screen and (max-width: 320px) {
    .AboutMe {
        width: 90%;
        height:auto;
        position: relative;
        border:0.5rem solid #FB6FC6;
        margin-top: 12rem;
        margin-left: 2rem;
        margin-bottom: -30%;
       }
    
 .AboutMeText {
        font-size: 0.6em;
       padding: 3%;
    }
}

@media only screen and (min-width: 321px) and (max-width: 360px) {
    .AboutMe {
        width: 90%;
        height:auto;
        position: relative;
        border:0.5rem solid #FB6FC6;
        left: 13rem;
        margin-bottom: -20%;
        margin-top: 45%;
       }
    
 .AboutMeText {
        font-size: 0.6em;
       padding: 1%;
    }
}

/* Ipads and Tablets */

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .AboutMe {
        width: 90%;
        height:auto;
        position: relative;
        border:0.5rem solid #FB6FC6;
        margin-top: 25%;
        left: 56%;
       }
    
 .AboutMeText {
        font-size: 0.6em;
       padding: 1%;
    }
}.AboutMeTitle {
    position: relative;
    height: auto;
    width: 70%;
    

}




@media only screen and (min-width: 1281px) and (max-width:1919px) {
    

.AboutMe {
    width: 80%;
    height: auto;
    margin: 0 auto;
    margin-top: 95px;
    position: relative;
    top: 50%;
    transform: translate(-59.5%, -10%);
    border:20px solid #FB6FC6;
    border-radius: 5px;
    background-color: #EDE768;
    box-shadow: 0 4px 8px 0 #534D56;
    padding: 20px;
 
}

.AboutMeTitle {
    position: relative;
    height: 20%;
    width: 30%;
    

}

.AboutMeText {
    font-size: 17.5px;
    padding: 20px;
}
}

