.EggSection {
  background-color: #83D4D4;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(1rem + 2vmin);
  
  }

  .UncrackedEgg {
    width: 80%;
    max-width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
  
  }

  .HatchingEgg {
    width: 60%;
    max-width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    animation-name: hatchAnimation;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    opacity: 0;
    margin-top: 2rem;
  }

  @keyframes hatchAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .HatchButton {
    width: 20%;
    max-width: 100%;
    height: auto;
    padding: 2%;
    justify-content: center;
    align-items: center;
    background-color: #fb6fc6;
    border: 5px solid #EDE768;
    box-shadow: 0px 0px 10px 0px #83D4D4;
    cursor: pointer;
    margin-bottom: 2rem;
    margin-top: 5%;
    color: #E9F4F8;
    font-size: 1rem;
    font-weight: bold;
    font-family: 'Fira Code', monospace;
  }

  .HatchButton:hover {
    background-color: #EDE768;
    border: 5px solid #fb6fc6;
    /* box-shadow: 0px 0px 10px 0px #83D4D4; */
    cursor: pointer;
  }

  .HatchButton:active {
    background-color: #EDE768;
    box-shadow: 0 5px #fb6fc6;
    transform: translateY(4px);
    box-shadow: 0px 4px #f49dd3;
  }
  /* Mobile Screen */
  @media only screen and (min-width: 320px) and (max-width: 480px) {

      .UncrackedEgg {
        width: 80%;
        max-width: 100%;
        height: auto;
      }
    
      .HatchingEgg {
        width: 100%;
        max-width: 100%;
        height: auto;
        margin-top: 10%;
      }
    
      .HatchButton {
        width: 30%;
        height: auto;
      }
  }

  @media only screen and (min-width: 1280px) and (max-width:1919px) {
    .EggSection {
      background-color: #83D4D4;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      
      }
    
      .UncrackedEgg {
        width: 60%;
        max-width: 100%;
        height: auto;
        justify-content: center;
        align-items: center;
      
      }
    
      .HatchingEgg {
        width: 60%;
        max-width: 100%;
        height: auto;
        justify-content: center;
        align-items: center;
        animation-name: hatchAnimation;
        
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        opacity: 0;
        margin-top: 2rem;
      }
    
      @keyframes hatchAnimation {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    
      .HatchButton {
        display: flex;
        width: 150px;
        height: 50px;
        justify-content: center;
        align-items: center;
        background-color: #fb6fc6;
        border: 5px solid #EDE768;
        box-shadow: 0px 0px 10px 0px #83D4D4;
        cursor: pointer;
        margin-bottom: 40px;
        margin-top: 15px;
        color: #E9F4F8;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Fira Code', monospace;
      }
    
      .HatchButton:hover {
        background-color: #EDE768;
        border: 5px solid #fb6fc6;
        /* box-shadow: 0px 0px 10px 0px #83D4D4; */
        cursor: pointer;
      }
    
      .HatchButton:active {
        background-color: #EDE768;
        box-shadow: 0 5px #fb6fc6;
        transform: translateY(4px);
      }
  }