.Skills {
    position: relative;
    background-color: #EDE768;
    min-height: 89vh;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #83D4D4;
    padding-bottom: 6rem;   
}

.SkillsTitle {
  position: absolute;
  top: 0;
  height: auto;
  width: 30%;   
}

#skills {
   
    padding-top: 4rem;
    position: relative;
  }
  
  .skills-container {
    position: absolute;
    top: 22rem;
    margin: 0 10%;
    padding-bottom: 10rem;
    bottom: 10;
    width: 80%;

  }
  
    .skills-row {
    position: relative;
  }
  
  .skills-content {
   
     display: flex;
     flex-wrap: wrap;
     justify-content: center;
     gap: 2rem;
    
    
  }
  
  .skills-slider {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 22rem;
    gap: 2rem;
    padding: 0 1rem;
  }

  .skills-slider::-webkit-scrollbar {
    height: 2rem; 
  }
  
  .skill-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 3rem;
    width: 120px;
    height: 130px;
    background: #FB6FC6;
    box-shadow: 0 1.25rem 2rem#FFAAEA;
    position: relative;
    transition: all 0.15s ease-in-out;
    color: #534D56;
    margin: 1rem; 
  }
  
  .skill-card:hover {
    transform: scale 0.85;
    box-shadow: 0 2rem 3rem #83D4D4;
  }
  
  .skill-card img {
    position: absolute;
    width: 70px;
    z-index: 1;
    transition: all 0.15s ease-in-out;
  }
  
  .skill-card:hover img {
    scale: 2;
    transform: translateY(-1.5rem);
  }
  
  .skill-card .skill-name {
    position: absolute;
    font-size: 20px;
    opacity: 0;
    z-index: 0;
    transition: all 0.15s ease-in;
  }
  
  .skill-card:hover .skill-name {
    display: block;
    transform: translateY(6rem);
    opacity: 1;
  }
  
  
  
  /* Mobile Screens */
  @media only screen and (min-width: 376px) and (max-width:480px) {
    .Skills {
      width: 100%;
      max-height: 150vh;
      height:auto;
      padding-bottom: 40%;       
  }
  
  .SkillsTitle {
    width: 70vw;
    height: auto;   
    top: -2rem;
  }
  
    .skills-container {
      width: 90%;
      height: auto;
      top: 12rem;
      margin-left: 1rem;

    }

    .skills-slider {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      height: 22rem;
      gap: 1rem;
      
    }
 
    .skill-card {
      width: 60px;
      height: 60px;
    }
    
    .skill-card img {
      width: 40px;
    }
    
    .skill-card:hover img {
      scale: 2;
      transform: translateY(-1.2rem);
    }
    
    .skill-card .skill-name {
      position: absolute;
      font-size: 0.75em;
      opacity: 0;
      z-index: 0;
      transition: all 0.15s ease-in;
    }
    
    .skill-card:hover .skill-name {
      display: block;
      transform: translateY(3.5rem);
      opacity: 1;
    }

  }
    
    @media only screen and (max-width: 320px) {
      .Skills {
        width: 100%;
        max-height: 120vh;
        height:auto;
        padding-bottom: 60%;
          
    }
    
    .SkillsTitle {
      width: 70vw;
      height: auto;   
      top: -2rem;
    }
    
      .skills-container {
        width: 90%;
        height: auto;
        top: 8rem;
        left: -1rem;
        
      }
  
      .skills-slider {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        height: 22rem;
        gap: 1rem;
        

        
      }
   

      .skill-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 3rem;
        width: 60px;
        height: 60px;
        background: #FB6FC6;
        box-shadow: 0 1.25rem 2rem#FFAAEA;
        position: relative;
        transition: all 0.15s ease-in-out;
        color: #534D56;
        margin: 0.45rem; 
      }
      
      .skill-card img {
        width: 40px;
      }
      
      .skill-card:hover img {
        scale: 2;
        transform: translateY(-1.2rem);
      }
      
      .skill-card .skill-name {
        position: absolute;
        font-size: 0.75em;
        opacity: 0;
        z-index: 0;
        transition: all 0.15s ease-in;
      }
      
      .skill-card:hover .skill-name {
        display: block;
        transform: translateY(3rem);
        opacity: 1;
      }
  
  }

  @media only screen and (min-width: 321px) and (max-width: 360px) {
    .Skills {
      width: 100%;
      max-height: 120vh;
      height:auto;
      padding-bottom: 25%;
        
  }
  
  .SkillsTitle {
    width: 70vw;
    height: auto;   
    top: -2rem;
  }
  
    .skills-container {
      width: 80%;
      height: auto;
      top: 9rem;
    }

    .skills-slider {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      height: 22rem;
      gap: 1rem;
      
    }
 

    .skill-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 3rem;
      width: 60px;
      height: 60px;
      background: #FB6FC6;
      box-shadow: 0 1.25rem 2rem#FFAAEA;
      position: relative;
      transition: all 0.15s ease-in-out;
      color: #534D56;
      margin: 0.45rem; 
    }
    
    .skill-card img {
      width: 40px;
    }
    
    .skill-card:hover img {
      scale: 2;
      transform: translateY(-1.2rem);
    }
    
    .skill-card .skill-name {
      position: absolute;
      font-size: 0.75em;
      opacity: 0;
      z-index: 0;
      transition: all 0.15s ease-in;
    }
    
    .skill-card:hover .skill-name {
      display: block;
      transform: translateY(3rem);
      opacity: 1;
    }

}

@media only screen and (min-width: 375px) and (max-width: 376px) {
  .Skills {
    width: 100%;
    max-height: 120vh;
    height:auto;
    padding-bottom: 25%;
      
}

.SkillsTitle {
  width: 70vw;
  height: auto;   
  top: -2rem;
}

  .skills-container {
    width: 80%;
    height: auto;
    top: 9rem;
  }

  .skills-slider {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 22rem;
    gap: 1rem;
    
  }


  .skill-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 3rem;
    width: 60px;
    height: 60px;
    background: #FB6FC6;
    box-shadow: 0 1.25rem 2rem#FFAAEA;
    position: relative;
    transition: all 0.15s ease-in-out;
    color: #534D56;
    margin: 0.45rem; 
  }
  
  .skill-card img {
    width: 40px;
  }
  
  .skill-card:hover img {
    scale: 2;
    transform: translateY(-1.2rem);
  }
  
  .skill-card .skill-name {
    position: absolute;
    font-size: 0.75em;
    opacity: 0;
    z-index: 0;
    transition: all 0.15s ease-in;
  }
  
  .skill-card:hover .skill-name {
    display: block;
    transform: translateY(3rem);
    opacity: 1;
  }

}

@media only screen and (min-width: 412px) and (max-width:428px) {
  .Skills {
    width: 100%;
    max-height: 120vh;
    height:auto;
    padding-bottom: 6.5rem;
      
}

.SkillsTitle {
  width: 70vw;
  height: auto;   
  top: -2rem;
}

  .skills-container {
    width: 90%;
    height: auto;
    top: 11rem;

  }

  .skills-slider {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 22rem;
    gap: 2rem;
    
  }


  .skill-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 3rem;
    width: 60px;
    height: 60px;
    background: #FB6FC6;
    box-shadow: 0 1.25rem 2rem#FFAAEA;
    position: relative;
    transition: all 0.15s ease-in-out;
    color: #534D56;
    margin: 0.45rem; 
  }
  
  .skill-card img {
    width: 40px;
  }
  
  .skill-card:hover img {
    scale: 2;
    transform: translateY(-1.2rem);
  }
  
  .skill-card .skill-name {
    position: absolute;
    font-size: 0.75em;
    opacity: 0;
    z-index: 0;
    transition: all 0.15s ease-in;
  }
  
  .skill-card:hover .skill-name {
    display: block;
    transform: translateY(3rem);
    opacity: 1;
  }
}

/* Ipads and Tablets */

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .Skills {
    position: relative;
    background-color: #EDE768;
    min-height: 89vh;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #83D4D4;
    padding-bottom:5rem;   
}

.SkillsTitle {
  position: absolute;
  height: auto;
  width: 50%;   
}

#skills {
   
    padding-top: 4rem;
    position: relative;
  }
  
  .skills-container {
    position: absolute;
    top: 15rem;
    margin: 0 10%;
    padding-bottom: 10rem;
    bottom: 10;
    width: 80%;

  }
  
    .skills-row {
    position: relative;
  }
  
  .skills-content {
   
     display: flex;
     flex-wrap: wrap;
     justify-content: center;
     gap: 2rem;
    
    
  }
  
  .skills-slider {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 22rem;
    gap: 1rem;
    padding: 0 1rem;
  }

  .skills-slider::-webkit-scrollbar {
    height: 2rem; 
  }
  
  .skill-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 3rem;
    width: 110px;
    height: 110px;
    background: #FB6FC6;
    box-shadow: 0 1.25rem 2rem#FFAAEA;
    position: relative;
    transition: all 0.15s ease-in-out;
    color: #534D56;
    margin: 0.7rem; 
  }
  
  .skill-card:hover {
    transform: scale 0.85;
    box-shadow: 0 2rem 3rem #83D4D4;
  }
  
  .skill-card img {
    position: absolute;
    width: 70px;
    z-index: 1;
    transition: all 0.15s ease-in-out;
  }
  
  .skill-card:hover img {
    scale: 2;
    transform: translateY(-1.5rem);
  }
  
  .skill-card .skill-name {
    position: absolute;
    font-size: 1rem;
    opacity: 0;
    z-index: 0;
    transition: all 0.15s ease-in;
  }
  
  .skill-card:hover .skill-name {
    display: block;
    transform: translateY(5rem);
    opacity: 1;
  }
}

  

  /* Normal Laptop Screens */

  @media (min-width: 1280px) and (max-width: 1919px) {
    .Skills {
      min-height: 100vh;
      width: 100%;
      padding-bottom: 6rem;
   }
  
   .SkillsTitle {
    position: absolute;
    top: 0;
    height: auto;
    width: 30%;    
  }
  
  #skills {
     
      padding-top: 4rem;
      position: relative;
    }
    
    .skills-container {
      position: absolute;
      top: 22rem;
      margin: 0 4%;
      padding-bottom: 10rem;
      bottom: 10;
      width: 90%;
   }

  }

  /* Mac Air*/
  @media only screen and (width: 1280px) {
    .Skills {
      position: relative;
      background-color: #EDE768;
      max-height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #83D4D4;
      padding-bottom: 12rem;
      
  }
  
  .SkillsTitle {
      position: absolute;
      top: 0;
      height: auto;
      width: 40%;
   }
  
   #skills {
     
      padding-top: 4rem;
      position: relative;
    }
    
    .skills-container {
      position: absolute;
      width: 90%;
      height: 100%;
      padding-bottom: 20rem;
      bottom: 10;
    }
    
   .skills-row {
      position: relative;
    }
    
    .skills-content {
      display: flex;  
    }
    
    .skills-slider {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      height: 22rem;
      gap: 3rem;
      padding: 0 1rem;
    }
  
    .skills-slider::-webkit-scrollbar {
      height: 2rem;
     
    }
    
    .skill-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 3rem;
      width: 120px;
      height: 110px;
      background: #FB6FC6;
      box-shadow: 0 1.25rem 2rem#FFAAEA;
      position: relative;
      transition: all 0.15s ease-in-out;
      color: #534D56; 
    }
    
    .skill-card:hover {
      scale: 0.85;
      box-shadow: 0 2rem 3rem #83D4D4;
    }
    
    .skill-card img {
      position: absolute;
      width: 70px;
      z-index: 1;
      transition: all 0.15s ease-in-out;
    }
    
    .skill-card:hover img {
      scale: 2;
      transform: translateY(-1.5rem);
    }
    
    .skill-card .skill-name {
      position: absolute;
      font-size: 20px;
      opacity: 0;
      z-index: 0;
      transition: all 0.15s ease-in;
    }
    
    .skill-card:hover .skill-name {
      display: block;
      transform: translateY(6rem);
      opacity: 1;
    }
    
  }