
.Header {
    position: relative;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    overflow:hidden;
    
}

.HeaderTitle {
    max-width: 100%;
    width: 80%;
    margin: 0;
}

.ProfileImg {
    max-width: 100%;
    width: 18%;
    height: auto;
    margin-top: 8rem;
    bottom: 0;
    position:absolute;
    right: 8rem;
    
   
    
}

.Goose {
    position: absolute;
    bottom: 0;
    max-width: 100%;
    height: auto;
    
}


/* Mobile Screens */

 @media only screen and (max-width: 480px)  {
    .Header {
        width: 100%; 
        height: 100vh;
      
       
    }

    .HeaderTitle {
        width: 100%;
        height: auto;
        margin-top: 0%;
        margin-left: 0%;
        

    }
    
    .ProfileImg {
        width: 50%;
        left: 11rem;
        
        
    }
    
    .Goose {
       width: 100%; 
       margin-bottom: 0%;
       left: 0%;

        
    }
} 

@media only screen and (max-width: 320px) {
    .ProfileImg {
        width: 45%;
        
    }

}

@media only screen and (min-width: 321px) and (max-width: 360px) {
    .ProfileImg {
        width: 50%;
        left: 10.5rem;    
    }

}

/* Ipads and Tablets */

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .Header {
        position: relative;
        height: 100vh;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        overflow:hidden;
        
    }
    
    .HeaderTitle {
        max-width: 100%;
        width: 80%;
        margin: 0;
    }
    
    .ProfileImg {
        max-width: 100%;
        width: 40%;
        height: auto;
        margin-top: 8rem;
        bottom: 0;
        position:absolute;
        right: 1rem;
        
       
        
    }
    
    .Goose {
        position: absolute;
        bottom: 0;
        max-width: 100%;
        height: auto;
        
    }
}




/* Normal Laptop Screens */

   @media (min-width: 1280px) and (max-width: 1919px) {
    .Header {
        width: 100%; 
    }
    
    .HeaderTitle {
        max-width: 100%;
        width: 70%;
        margin-top: 0%;
        margin-left: 2%;
        
       
    }
    
    .ProfileImg {
        max-width: 100%;
        width: 17.5%;
        margin-top: 9%;
        margin-left: 3%;
    }
    
    .Goose {
       width: 60%;  
       bottom: 0%;
       left: 35%;
       transform: translateX(-50%);
        
    }
}   

