/* New Parent CSS */

.MemPage {
    background-color: #EDE768;
    color: hotpink; 
    text-align: center; 
}


.MemTitle {
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    
}

.MemOctoCat {
    width: 10%; 
    height: auto; 
    margin-top: 1rem;
    margin-bottom: 4rem; 
}

.MemOctoCat:hover {
    cursor: pointer;
}

.MemOctoCat:active {
    transform: scale(1.1);
}

.MemAppEgg {
    width: 8%;
    height: auto; 
    margin-bottom: 4rem; 
    margin-top: 1rem; 
}

.MemAppEgg:hover {
    cursor: pointer;
}

.MemAppEgg:active {
    transform: scale(1.1);
}

.MemApp {
    width: 100%; 
    height: auto; 
    margin: 3rem 0; 
    border-radius: 1rem;
    max-width: 900px; 
    margin-bottom: 2rem;
    margin-left: 5rem;
    margin-right: 2rem;
}

.MemContainer {
    display: flex;
    align-items: flex-start;
    margin-bottom: 2rem;
}

.MemText {
    display:flex;
    flex:0.9;
    flex-direction: column;
    justify-content: flex-end;
    position:relative;
    align-self: flex-start;
    text-align: left;
    font-size: 1.12em;
    color: #534D56;

}



/*Normal Laptop Screens*/
@media only screen and (min-width: 1280px) and (max-width: 1600px) {
    .MemPage {
        height: 135vh;
        background-color: #EDE768;
        color: hotpink
    }
    
    .MemTitle {
        width: 80%;
        margin-top: 0px;
        margin-bottom: -10px;
       
        
    }
    
    .MemOctoCat {
        width: 12%;
        height: auto;
        margin-bottom: 0px;
        margin-left: 1%;
        
    }
    
    .MemOctoCat:hover {
        cursor: pointer;
    }
    
    .MemOctoCat:active {
        /* background-color: #EDE768; */
        transform: scale(1.1);
    }
    
    .MemAppEgg {
        width: 10%;
        height: auto;
        margin-bottom: 0px;
        margin-left: -7%;
      
    }
    
    .MemAppEgg:hover {
        cursor: pointer;
    }
    
    .MemAppEgg:active {
        /* background-color: #EDE768; */
        transform: scale(1.1);
    }
    
    .MemApp {
        width: 60%;
        margin-left: 50px;
        margin-right: 20px;
        margin-bottom: 30px;
        margin-top: 40px;
        border-radius:30px;
    }
    
    .MemContainer {
        display: flex;
        align-items: flex-start;
        
    }
    .MemText {
        display:flex;
        flex:0.9;
        flex-direction: column;
        justify-content: flex-end;
        position:relative;
        align-self: flex-start;
        font-size: 1.05em;
        color: #534D56;
        
    }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
    .MemPage {
        width: 100%;
        height: auto;
        background-color: #EDE768;
        color: hotpink
    }
    
    .MemTitle {
        width: 100%;
        height: auto;
        }
    
    .MemOctoCat {
        width: 100px;
        height: 90px;
        margin: 0 auto;
        margin-bottom: 75px;
        
    }
    
    .MemOctoCat:hover {
        cursor: pointer;
    }
    
    .MemOctoCat:active {
        /* background-color: #EDE768; */
        transform: scale(1.1);
    }
    
    .MemAppEgg {
        width: 80px;
        height: 95px;
        margin: 0 auto;
        margin-bottom: 75px;
        
      
    }
    
    .MemAppEgg:hover {
        cursor: pointer;
    }
    
    .MemAppEgg:active {
        /* background-color: #EDE768; */
        transform: scale(1.1);
    }
    
    .MemApp {
        width: 90%;
        height: auto;
        margin-left: 2rem;
        margin-top: -30px;
        border-radius: 10px;
    }
    
    .MemContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        
    }
    .MemText {
        display:flex;
        flex:1;
        flex-direction: column;
        position:relative;
        align-self: flex-start;
        text-align: center;
        font-size: 1em;
        color: #534D56;
        padding: 0 10px;
        
    }
    
}

 /* Mac Air*/

@media (min-width: 768px) and (max-width: 1280px) {
    .MemPage {
        height: 135vh;
        background-color: #EDE768;
        color: hotpink
    }
    
    .MemTitle {
        width: 80%;
        margin-top: 0px;
        margin-bottom: -10px;
        margin-left: -3rem;
       
        
    }
    
    .MemOctoCat {
        width: 145px;
        height: 125px;
        margin-bottom: 0px;
        margin-left: 0%;
        
    }
    
    .MemOctoCat:hover {
        cursor: pointer;
    }
    
    .MemOctoCat:active {
        /* background-color: #EDE768; */
        transform: scale(1.1);
    }
    
    .MemAppEgg {
        width: 120px;
        height: 130px;
        margin-bottom: 0px;
       
      
    }
    
    .MemAppEgg:hover {
        cursor: pointer;
    }
    
    .MemAppEgg:active {
        /* background-color: #EDE768; */
        transform: scale(1.1);
    }
    
    .MemApp {
        width: 60%;
        margin-left: 50px;
        margin-right: 20px;
        margin-bottom: 30px;
        margin-top: 40px;
        border-radius:30px;
    }
    
    .MemContainer {
        display: flex;
        align-items: flex-start;
        
    }
    .MemText {
        display:flex;
        flex:0.9;
        flex-direction: column;
        justify-content: flex-end;
        position:relative;
        align-self: flex-start;
        font-size: 1.05em;
        color: #534D56;
        
    }
}