

.LottoPage {
    background-color: #EDE768;
    color: hotpink; 
    text-align: center; 
}

.LottoTitle {
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    margin-bottom: 1rem; 
    margin-left: -7rem;
}

.OctoCat {
    width: 10%; 
    height: auto; 
    margin-top: 1rem;
    margin-bottom: 2rem; 
}

.OctoCat:hover {
    cursor: pointer;
}

.OctoCat:active {
    transform: scale(1.1);
}

.LottoEgg {
    width: 8%;
    height: auto; 
    margin-bottom: 2rem; 
    margin-top: 1rem; 
}

.LottoEgg:hover {
    cursor: pointer;
}

.LottoEgg:active {
    transform: scale(1.1);
}

.LottoApp {
    width: 100%; 
    height: auto; 
    margin: 3rem 0; 
    border-radius: 1rem;
    max-width: 900px; 
    margin-bottom: 2rem;
    margin-left: 5rem;
    margin-right: 2rem;
    
}

.LottoContainer {
    display: flex;
    align-items: flex-start;
    margin-bottom: 2rem;
}

.LottoText {
    display:flex;
    flex:0.9;
    flex-direction: column;
    justify-content: flex-end;
    position:relative;
    align-self: flex-start;
    text-align: left;
    font-size: 1.12em;
    color: #534D56;

}

.HomeEgg1 {
    display: flex;
    flex-direction: column; 
    align-items: center; 
    text-align: center; 
    
}








/* Normal Laptop Screens */
@media only screen and (min-width: 1280px) and (max-width:1919px) {

    .LottoPage {
        height: 100%;
        background-color: #EDE768;
        color: hotpink
    }
    
    .LottoTitle {
        margin-bottom: 20px;
        width: 80%;
       
        
    }
    
    .OctoCat {
        width: 12%;
        height: auto;
        margin-bottom: 0px;
        margin-left: 1%;
    
        
    }
    
    .OctoCat:hover {
        cursor: pointer;
    }
    
    .OctoCat:active {
        /* background-color: #EDE768; */
        transform: scale(1.1);
    }
    
    .LottoEgg {
        width: 10%;
        height: auto;
        margin: 0 auto;
        margin-bottom: -0%;
        margin-left: -8%;

      
    }
    
    .LottoEgg:hover {
        cursor: pointer;
    }
    
    .LottoEgg:active {
        /* background-color: #EDE768; */
        transform: scale(1.1);
    }
    
    .LottoApp {
        width: 50%;
        height: 50%;
        margin-left: 70px;
        margin-right: 20px;
        margin-top: 30px;
        margin-bottom: 30px;
        border-radius:30px;
    }
    
    .LottoContainer {
        display: flex;
        align-items: flex-start;
        
    }
    .LottoText {
        display:flex;
        flex:0.9;
        flex-direction: column;
        justify-content: flex-end;
        position:relative;
        align-self: flex-start;
        font-size: 1.05em;
        color: #534D56;
        
    }
    
    .HomeEgg1 {
       display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position:relative;
        margin-bottom:20px;
}



}
/*Mobile Screens */
@media only screen and (min-width: 320px) and (max-width: 480px) {

    .LottoPage {
        background-color: #EDE768;
        color: hotpink
    }
    
    .LottoTitle {
        width: 100%;
        height: auto;
        padding-left: 6rem;
     }
    
    .OctoCat {
        width: 100px;
        height: 90px;
        margin: 0 auto;
        margin-bottom: 75px;
        margin-left: 3%;
        
    }
    
    .OctoCat:hover {
        cursor: pointer;
    }
    
    .OctoCat:active {
        /* background-color: #EDE768; */
        transform: scale(1.1);
    }
    
    .LottoEgg {
        width: 80px;
        height: 95px;
        margin: 0 auto;
        margin-bottom: 75px;
       
       
      
    }
    
    .LottoEgg:hover {
        cursor: pointer;
    }
    
    .LottoEgg:active {
        /* background-color: #EDE768; */
        transform: scale(1.1);
    }
    
    .LottoApp {
        width: 90%;
        height: auto;
        margin-left: 2rem;
        margin-top: -30px;
    }
    
    .LottoContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        
    }
    .LottoText {
        display:flex;
        flex:1;
        flex-direction: column;
        position:relative;
        align-self: flex-start;
        text-align: center;
        font-size: 1em;
        color: #534D56;
        padding: 0 10px;
        
    }
    
    .HomeEgg1 {
       display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position:relative;
        margin-bottom:20px;
        
    
      
    }
   
    
      
    }

    /* Large Screens */

   