
.Footer {
    background-color: #83D4D4;
    height: 25vh;
    max-width: 100vw;  
    padding: 1rem 0;
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    justify-content: center;
   


}

.FooterImg {
    position: absolute;
    width: 40%;
    height: auto;
    

}

    /* Normal Laptop Screens inc Mac Air */

    @media (min-width: 1280px) and (max-width: 1919px) {
        .Footer {
            background-color: #83D4D4;
            height: 28vh;
            max-width: 100%;
            display: flex; 
            flex-direction: column; 
            align-items: center; 
            justify-content: center;
            padding: 1rem 0;
        }
    
        .FooterImg {
            width: 40%;
            height: auto;
        }
    }
    
    
   
  

/* Small Laptop Screens */

/* @media only screen and (max-width: 1280px) and (max-width: 1600px) {
    .Footer {
        background-color: #83D4D4;
        height: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; 
    
    }
    
    .FooterImg {
        position: absolute;
        width:50%;
        height: 30%;
        
    
    }
    
    .GooseFooter {
       position: relative;
       width: 100%;
       height: 70%;
       margin-top: -41%;
       
      
       
    
    }
} */

/* Mobile Screens */

@media only screen and (min-width: 320px) and (max-width: 480px) {
    .Footer {
        width: 100%;
        height: auto;
        padding: 2rem 0;
    }

    .FooterImg {
        width: 70%;
        height: auto;
    }

}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .Footer {
        width: 100%;
        height: auto;
        padding: 3rem 0;
    }

    .FooterImg {
        width: 60%;
        height: auto;
    }
}

/* @media only screen and (min-width: 1920px) and (max-width: 2560px) {
    .Footer {
        background-color: #83D4D4;
        min-height: 26vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    
    }
    
    
    .GooseFooter {
       position: absolute;
       margin-top: -21%;
       margin-left: 0%;
}

} */
