body {
  background-color: #EDE768;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  
}

.App {
  position: absolute;
  text-align: center;
  background-color: #EDE768;
  height: 100vh;
  width: 100vw;
  
  
}



.App-header {
  background-color: #EDE768;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(1rem + 2vmin);
  color: #EDE768;
}


@media only screen and (min-width: 1280px) and (max-width: 1600px) {

.App-header {
  min-width: 80%;
}

}


@media only screen and (max-width: 390px) {
  body {
    height: auto; 
    width: 100%;  
  }
}




@media (min-width: 768px) and (max-width: 1024px)  {

.App-header {
 min-width: 80%;
}



}