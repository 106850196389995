
.Contact {
    position: relative;
    background-color: #EDE768;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #83D4D4;
    padding: 2rem;
    
}

.ContactTitle {
   width: 100%;
   height: auto;
   top: 0;
   padding-bottom: 2rem;
   

    
}

#contact {
   
    padding-top: 1rem;
    position: relative;
  }
  
  .contact-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto; 
    margin: 2rem 0;

  }
  
 
  .contact-row {
    position: relative;
  }
  
  .contact-content {
   
     display: flex;
    
    
  }
  
  .contact-slider {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 22rem;
    gap: 2rem;
    padding: 0 1rem;
   
  
  }
  

  
  .contact-slider::-webkit-scrollbar {
    height: 2rem;
   
  }
  
  .contact-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 3rem;
    width: 120px;
    height: 130px;
    background: #FB6FC6;
    box-shadow: 0 1.25rem 2rem#FFAAEA;
    position: relative;
    transition: all 0.15s ease-in-out;
    color: #534D56; 
    margin: 1rem;
  }
  
  .contact-card:hover {
    transform: scale (0.85);
    box-shadow: 0 2rem 3rem #83D4D4;
  }
  
  .contact-card img {
    display: flex;
    width: 70px;
    z-index: 1;
    transition: all 0.15s ease-in-out;
  }
  
  .contact-card:hover img {
    scale: (2);
    transform: translateY(-1.5rem);
  }
  
  .contact-card .contact-name {
    position: absolute;
    font-size: 20px;
    opacity: 0;
    z-index: 0;
    transition: all 0.15s ease-in;
  }
  
  .contact-card:hover .contact-name {
    display: block;
    transform: translateY(6rem);
    opacity: 1;
  }

  .GooseFooter {
    width: 50%; 
    height: auto;
    position: absolute;
    bottom: 0;
    left: 30%;
   
    
 
 }
  

  
 
  
  @media screen and (max-width: 1023px) {
  
  }
  
  @media screen and (max-width: 599px) {
  
    .contact-container {
      margin: 0 10%;
    
    }
  
  }

  /* Mobile Screens */
  @media only screen and (max-width: 480px) {
    .Contact {
      width: 80%;
     
      
  }
  
  .ContactTitle {
     width: 100%;
     height: auto;
     
    }

    .GooseFooter {
      width: 80%; 
      height: auto;
      position: absolute;
      bottom: 0;
      left: 20%;
    }
  
  #contact {
     
      padding-top: 4rem;
      position: relative;
    }
    
    .contact-container {
      position: absolute;
      width: 100%;
      height: 100%;
      padding-bottom: 20rem;
      bottom: 0;
  
    }
    
   
    .contact-row {
      position: relative;
    }
    
    .contact-content {
     
       display: flex;
      
      
    }
    
    .contact-slider {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 20rem;
      height: auto;
      gap: 1rem;
      
     
    
    }
    
  
    
    .contact-slider::-webkit-scrollbar {
      height: 2rem;
     
    }
    
    .contact-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 3rem;
      width: 60px;
      height: 60px;
      background: #FB6FC6;
      box-shadow: 0 1.25rem 2rem#FFAAEA;
      position: relative;
      transition: all 0.15s ease-in-out;
      color: #534D56; 
    }
    
    .contact-card:hover {
      scale: 0.85;
      box-shadow: 0 2rem 3rem #83D4D4;
    }
    
    .contact-card img {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      align-items: center;
      width: 40px;
      z-index: 1;
      transition: all 0.15s ease-in-out;
    }
    
    .contact-card:hover img {
      scale: 2;
      transform: translateY(-1.5rem);
    }
    
    .contact-card .contact-name {
      position: absolute;
      font-size: 1em;
      opacity: 0;
      z-index: 0;
      transition: all 0.15s ease-in;
    }
    
    .contact-card:hover .contact-name {
      display: block;
      transform: translateY(4rem);
      opacity: 1;
    }
  }

  @media only screen and (max-width: 320px) {
    .contact-slider {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 18rem;
      height: auto;
      gap: 1rem;
    }
    
    .contact-slider::-webkit-scrollbar {
      height: 2rem;
     
    }
    
    .contact-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 3rem;
      width: 60px;
      height: 60px;
      background: #FB6FC6;
      box-shadow: 0 1.25rem 2rem#FFAAEA;
      position: relative;
      transition: all 0.15s ease-in-out;
      color: #534D56; 
      margin: 0.45rem;
    }

  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .Contact 

      .Contact {
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        
    }
    
    .ContactTitle {
       width: 70%;
       height: auto;
       top: 0;
       padding-bottom: 2rem;
     }
    
     .contact-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 3rem;
      width: 110px;
      height: 110px;
      background: #FB6FC6;
      box-shadow: 0 1.25rem 2rem#FFAAEA;
      position: relative;
      transition: all 0.15s ease-in-out;
      color: #534D56; 
      margin: 0.7rem;
    }
}
  
  /* Normal Laptop Screens*/

  @media (min-width: 1280px) and (max-width: 1919px) {
    .Contact {
      position: relative;
      width: 95%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      
      
  }
  
  .ContactTitle {
     width: 100%;
     height: auto;
     top: 0;
     padding-bottom: 2rem;
     
  
  
      
  }
  
  }
